import React from "react";

function FooterTitle({ title }) {
    return (
        <div className="title-footer">
            <h2>{title}</h2>
        </div>
    );
}

export default FooterTitle;
