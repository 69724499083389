import React from "react";

function MachineImgAdd({image, alt, onClick}) {
  return (
          <div className="product-image-container">
              <img className="product-image" src={`../../img/machines/${image}`} alt={alt} onClick={onClick} />
          </div>
  );
}

export default MachineImgAdd;
