import React from "react";
import { useTranslation } from "react-i18next";

import "./DomainAdd.jsx";
import DomainAdd from "./DomainAdd.jsx";
import { NavLink } from "react-router-dom";

function Domain() {
    const { t } = useTranslation();


    return (


        <div className="domains">
            <div className="title-domain">TECHNOLOGIES</div>
            <div className="containerProd">

                {/*********************************************************************************** 
            Add a domain :
            
            <DomainAdd
                name="Name of category"                                  (Put in translation file in domain section)
                link="Link for redirection"                              (categories/)
                image="Name of image with extension"                     (public/img/machines/)
            />

            Limited to 3 domains (recommended)
            For more domains, change the number of css " .containerProd .card:not(:nth-child(X)) "
            X = number of domains
            ***********************************************************************************/}

                {/* <DomainAdd name={t("domain.MEB")} link="MEB-Ebeam" image="MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 9.jpg"  /> */}
                <DomainAdd name={t("domain.MEB")} link="MEB-Ebeam" image="../assets/MEB-domain.jpg" />
                {/* <DomainAdd name={t("domain.MP")} link="MP-Sputtering" image="MP-Sputtering/MP-Sputtering Cover.jpg"/> */}
                <DomainAdd name={t("domain.MP")} link="MP-Sputtering" image="MP-Sputtering/MP 700 S/MP-Sputtering - PLASSYS MP 700 S - 2.jpg" />
                {/* <DomainAdd name={t("domain.ME")} link="ME-Thermal" image="../assets/ME-Thermal-cover.jpg"  /> */}
                <DomainAdd name={t("domain.ME")} link="ME-Thermal" image="../assets/4.jpg" />
                {/* <DomainAdd name={t("domain.CVD")} link="CVD Diamond" image="CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 11.jpg"  /> */}
                <DomainAdd name={t("domain.CVD")} link="CVD Diamond" image="CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 1.jpg" />
                {/* <DomainAdd name={t("domain.Customized")} link="Customized" image="CUSTOM/Custom Cover.jpg"  /> */}
                <DomainAdd name={t("domain.Customized")} link="Customized" image="CUSTOM/MPE 600 S/CUSTOM - PLASSYS MPE 600 S - 2.jpg" />
                <DomainAdd name={t("domain.Software")} link="Software" image="../dalek/dalek-cover.jpg" />



            </div>
            <div className="button-container">
                <NavLink to="/categories" className="more">
                    <button className="learnmore">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                        </svg>
                        <div className="learnmoretext">
                            {t('domain.more')}
                        </div>
                    </button>
                </NavLink>
            </div>
        </div>

    )
}

export default Domain;