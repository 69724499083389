import React from "react";
import { useTranslation } from "react-i18next";

function Vitrine() {
  const { t } = useTranslation();

  const handleScroll = () => {
    const presContainer = document.getElementById('pres-container');
    const navbar = document.getElementById('navbar');
    if (presContainer && navbar) {
      const scrollPosition = presContainer.getBoundingClientRect().top + window.pageYOffset - navbar.offsetHeight;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <div className="filtre-sombre">

      <div className="img-vid-de-fond">
        <div className="dgrad-wrapper">
          <div className="dgrad"></div>
        </div>
        <video autoPlay loop muted playsInline id="video">
          <source src="img/assets/video.mp4" type="video/mp4"></source>
        </video>
        <div className="video-overlay">

        </div>
      </div>

      <div className="text-vitrine">
        <div className="logoTransparent"></div>
        <div className="flexcontainer">
          <span className="text">{t('vitrine.WWRef1')}</span>
          <span className="text">{t('vitrine.WWRef2')}</span>
          <div className="dessin">
            {/* <div className="rectangle"></div>
            <div className="rectangle-2"></div> */}
          </div>
          <div className="arrow-scroll" id="arrow-scroll" onClick={handleScroll}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Vitrine;