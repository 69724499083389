import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'

import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { BorderColor } from '@mui/icons-material';
ChartJS.register(ArcElement, Tooltip, Legend);


export default function Map() {
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const legendTextColor = rootStyles.getPropertyValue('--dark-text').trim();
  const [geoUrl, setGeoUrl] = useState("");
  const [activeContinent, setActiveContinent] = useState(null);
  const geographyRefs = useRef([]);
  const tooltipRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const [allData, setAllData] = useState(null);
  const [translatedName, setTranslatedName] = useState("Continent");
  const continentNames = {
    "Asia": "map.continent.asia",
    "Europe": "map.continent.europe",
    "Africa": "map.continent.africa",
    "America": "map.continent.america",
    "Oceania": "map.continent.oceania",
  };

  useEffect(() => {
    if (activeContinent && allData && !chartData[activeContinent.name]) {
      const data = getChartData(activeContinent, allData);
      setChartData(prevData => ({ ...prevData, [activeContinent.name]: data }));
    }
  }, [activeContinent, allData]);

  useEffect(() => {
    const storedMap = localStorage.getItem('mapData');
    if (storedMap) {
      const data = JSON.parse(storedMap);
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      setGeoUrl(URL.createObjectURL(blob));
      geographyRefs.current = Array(data.features.length).fill(null).map((_, i) => geographyRefs.current[i] ?? React.createRef());
    } else {
      fetch('/doc/map/mapLowSimple.json') //GeoJSON file
        .then((response) => response.json())
        .then((data) => {
          const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
          setGeoUrl(URL.createObjectURL(blob));
          geographyRefs.current = Array(data.features.length).fill(null).map((_, i) => geographyRefs.current[i] ?? React.createRef());
          localStorage.setItem('mapData', JSON.stringify(data));
        });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setActiveContinent(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetch('/doc/map/machineZone.json')
      .then(response => response.json())
      .then(data => {
        setAllData(data);
      });
  }, []);

  useEffect(() => {
    if (activeContinent) {
      setTranslatedName(t(continentNames[activeContinent.name]));
    }
  }, [activeContinent, t]);

  const getSpacedColors = (colors, count) => {
    const interval = Math.floor(colors.length / count);
    return Array.from({ length: count }, (_, i) => colors[i * interval]);
  };

  const getChartData = (continent, allData) => {
    const continentData = allData[continent.name];

    const labels = continentData.map(item => item.country);
    const dataChart = continentData.map(item => item.number);

    // // pastel
    // const colors = [
    //   '#ed6d85', // Rose moyen
    //   '#c51b7d', // Rose foncé
    //   '#8e0152', // Violet foncé
    //   '#5e4fa2', // Bleu foncé
    //   '#3288bd', // Bleu moyen
    //   '#66c2a5', // Vert moyen
    //   '#abdda4', // Vert clair
    //   '#e6f598', // Vert très clair
    //   '#ffffbf', // Jaune pâle
    //   '#fee08b', // Jaune clair
    //   '#fdae61', // Orange
    //   '#f46d43', // Orange foncé
    //   '#d53e4f', // Rouge
    //   '#9e0142', // Rouge foncé
    //   '#ff7f00', // Orange vif
    //   '#ffb3b3'  // Rose pâle
    // ];

        // pastel
    const colors = [
      '#d4b9da', // Moyen clair
      '#c994c7', // Moyen
      '#df65b0', // Moyen foncé
      '#e7298a', // Foncé
      '#ce1256', // Très foncé
      '#980043', // Très très foncé
      '#67001f', // Extrêmement foncé
      '#58355E', // Violet 
      '#ffb3b3', // Rose pâle
      '#ff7f00', // Orange vif
      '#9e0142', // Rouge foncé
      '#d53e4f', // Rouge
      '#f46d43', // Orange foncé
      '#fdae61', // Orange
      '#fee08b', // Jaune clair
      '#ffffbf'  // Jaune pâle
    ];

    const selectedColors = getSpacedColors(colors, labels.length);

    return {
      labels: labels,
      fontColor: 'white',
      datasets: [
        {
          data: dataChart,
          backgroundColor: selectedColors,
          borderWidth: 0,
        }
      ]
    };
  };

  const showTooltip = (geo, index) => {
    setActiveContinent(geo.properties);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 768px)'
  });



  return (
    <div className="map-container">
      <div className="title-map">{t('map.title')}</div>

      {activeContinent && chartData[activeContinent.name] && (
        <div ref={tooltipRef} className={`map-tooltip ${activeContinent ? 'active' : ''}`}>
          <div className='map-tooltip-titles'>
            <div className='map-tooltip-title'>{translatedName}</div>
            <div className='map-tooltip-close' onClick={() => setActiveContinent(null)}>x</div>
          </div>
          <div className='map-tooltip-content'>
            {chartData[activeContinent.name].datasets[0].data.length > 0 ? (
              <>
                <div className='map-tooltip-content-text'>
                  <div>{t('map.total')} : {chartData[activeContinent.name].datasets[0].data.reduce((a, b) => a + b, 0)}</div>
                  <div>{t('map.filter')}</div>
                </div>
                <div className='map-tooltip-content-chart'>
                  <Pie
                    data={chartData[activeContinent.name]}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          labels: {
                            color: legendTextColor,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <div className='map-tooltip-nochart'>{t('map.noMachines')}</div>
            )}
          </div>
        </div>
      )}

      {isDesktopOrLaptop && geoUrl ? (
        <ComposableMap className='worldmap-container'
          projectionConfig={{
            rotate: [0, 0, 0],
            scale: 205,
            translate: [800 / 2, 450 / 2]
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo, index) => (
                <React.Fragment key={geo.rsmKey}>
                  <Geography
                    geography={geo}
                    onClick={() => {
                      if (activeContinent && activeContinent.id === geo.properties.id) {
                        setActiveContinent(null);
                      } else {
                        showTooltip(geo, index);
                      }
                    }}
                    className={`map ${activeContinent && activeContinent.id === geo.properties.id ? "map-active" : "map-default"}`}

                  />
                </React.Fragment>
              ))
            }
          </Geographies>
        </ComposableMap>
      ) : (
        <div className='map-subtext'>
          {t('map.subtext')}
        </div>
      )}
    </div>
  );
}