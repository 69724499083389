/////////////////////////////////////////////
//Dependencies
import React from 'react'
import { NavLink } from 'react-router-dom';

/////////////////////////////////////////////
//CSS
import "./Banner.css";

export default function Banner({ imagelogo, imagebg, title, subtitle, link, name }) {
    return (
        <>
            <div className="banner-container" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(img/assets/${imagebg})`}}>
                <div className="banner-image">
                    <img src={`img/logo/${imagelogo}.png`} alt={`banner${imagelogo}`} />              
                </div>
                <div className="banner-content">
                    <div className="banner-text">
                        <div className="banner-title">{title}</div>
                        {/* <div className="banner-subtitle">{subtitle}</div> */}
                    </div>
                    <div className="banner-button">
                        <NavLink className='more' to={`/${link}`}>
                            <button className='banner-button-discover'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path></svg>
                                <div className='banner-button-discover-text'>{name}</div>
                            </button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}
