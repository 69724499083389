/////////////////////////////////////////////
//Dependencies
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

/////////////////////////////////////////////
//CSS
import "yet-another-react-lightbox/styles.css";
import "./css/MachineDet.css";

/////////////////////////////////////////////
//Components
import MachineImgAdd from "../Modules/machines/MachineImgAdd";
import ParseText from "../Modules/parseText/ParseText";

//////////////////////////////////////////////////////////////////////////////////////////

export default function MachineDet() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { machineName, categoryName } = useParams();
  console.log("MachineDet -> machineName:", machineName);
  console.log("MachineDet -> categoryName:", categoryName);

  document.title = `Plassys ▪ ${machineName}`;


  // Access the categories object
  const machines = t("machines", { returnObjects: true });
  const machineData = machines[machineName];
  const machineDescription = machineData ? machineData.desc : "Machine introuvable";

  console.log("MachineDet -> machines:", machines);
  console.log("MachineDet -> machineData:", machineData);
  console.log("MachineDet -> machineDescription:", machineDescription);

  const [description, setDescription] = useState(machineDescription);
  const [isLoading, setIsLoading] = useState(true);

  // Loading data effect
  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // If the data is still loading, do nothing
    if (isLoading) {
      return;
    }
    // Update the description if the machine data changes else redirect to the category page
    if (machineData) {
      setDescription(machineData.desc);
    }
  }, [machineData, navigate, categoryName, isLoading]);

  const handleGoBack = () => {
    navigate(`/categories/${categoryName}`);
  };


  // Verify that the machine index is valid
  if (machineData) {
    // Machine data
    const machine = machineData;

    const slides = machine.image.map((img, index) => ({
      src: `../../img/machines/${img}`,
      width: 800,
      height: 800,
      key: `machine-image-${index}`
    }));

    const params = {
      wrapAround: true,
      slidesToShow: 1,
      animation: "zoom",
      autoplay: true,
      autoplayInterval: 5000,
      enableKeyboardControls: true,
      adaptativeHeight: true,
      adaptiveHeightAnimation: true,
      cellSpacing: 30,

      renderCenterLeftControls: ({ previousSlide }) => (
        <div className="nuka-carousel-prev" onClick={previousSlide}></div>
      ),
      renderCenterRightControls: ({ nextSlide }) => (
        <div className="nuka-carousel-next" onClick={nextSlide}></div>
      ),
      renderBottomCenterControls: ({ currentSlide }) => {
        const numImages = machine.image.length;
        const hideDotsClass = numImages > 10 ? "hide-dots-on-mobile" : "";

        const color = getComputedStyle(document.documentElement).getPropertyValue('--dark-text');
        return (
          <div style={{ color: color.trim() }} className={hideDotsClass}>
            {Array.from(Array(numImages).keys()).map((index) => (
              <span
                key={index}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                  marginRight: 5,
                  fontSize: 20,
                  opacity: currentSlide === index ? 1 : 0.5,
                  transition: "0.5s all",
                }}
              >
                ●
              </span>
            ))}
          </div>
        );
      },
    };





    return (
      <div className="product-details-container">

        {/*Button*/}
        <button className="backbutton" onClick={handleGoBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            ></path>
          </svg>
          <div className="backbuttontext">{t('annexes.back-button')}</div>
        </button>

        {/*Title*/}
        <div className="product-details-top">
          <h1 className="product-details-title">{machineName}</h1>
          <ParseText text={machine.desc2} />
        </div>

        <div className="product-details-content">

          {/*Carousel*/}
          <div className="product-details-image">
            <Carousel {...params}>
              {machine.image.map((img, index) => (
                <MachineImgAdd key={index} image={img} alt={`machine-${index}`} onClick={() => {
                  setPhotoIndex(index);
                  setLightboxOpen(true);
                }} />
              ))}
            </Carousel>

            <Lightbox
              slides={slides}
              open={lightboxOpen}
              index={photoIndex}
              padding={10}
              close={() => setLightboxOpen(false)}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />

          </div>

          {/*Description*/}
          <div className="product-details-description">
            
            <div className="product-details-description-wrapper">
              <h2 className="product-details-description-title">Description</h2>
              <ParseText text={machine.desc} classN={"product-details-description-text"} />
            </div>

            <div className="button-container-machdet">
              {machine.tags.includes('MEB-Ebeam') ? (
                <a className='machdet-document-download-item-button' href='/doc/MEB-ModelCode.pdf' download>
                  MEB Model Code
                  <div className='machdet-document-download-item-button-icon'>
                  </div>
                </a>
              ) : machine.tags.includes('MP-Sputtering') ? (
                <a className='machdet-document-download-item-button' href='/doc/MP-ModelCode.pdf' download>
                  MP Model Code
                  <div className='machdet-document-download-item-button-icon'>
                  </div>
                </a>
              ) : null}
              <a className='machdet-document-download-item-button' href='/doc/PlassysOverview.pdf' download>
                Overview of categories
                <div className='machdet-document-download-item-button-icon'>
                </div>
              </a>
              <NavLink to="/contact" className="machdet-contact-link">
                <button className="machdet-contact-button">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                  </svg>
                  <div className="machdet-contact-button-text">
                    {t('annexes.contact-button')}
                  </div>
                </button>
              </NavLink>
            </div>

          </div>

        </div>

      </div>
    );
  } else {
    return (
      <div className="NF-details-container">

        <button className="backbutton" onClick={handleGoBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            ></path>
          </svg>
          <div className="backbuttontext">{t('annexes.back-button')}</div>
        </button>

        <div className="NF-content">
          <h1 className="NF-details-title">{machineName} machine not found</h1>
          <div className="NF-details-content">
            <p className="NF-details-description">
              {t("notfound.machine")}
            </p>

          </div>
        </div>
      </div>

    );
  }
}

