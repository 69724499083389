import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function NavbarDropdown({ linkInfo }) {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        if (!hasInteracted) setHasInteracted(true);
        setDropdownVisible(true);
    };

    const hideDropdown = (event) => {
        if (event) {
            event.stopPropagation();
        }
        if (!hasInteracted) setHasInteracted(true);
        setDropdownVisible(false);
    };

    const handleOutsideClick = (event) => {
        if (event && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            hideDropdown();
        }
    };

    const getDropdownMenuClass = () => {
        if (!hasInteracted) return 'dropdown-menu init';
        return `dropdown-menu ${isDropdownVisible ? 'show' : 'hide'}`;
    }

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownVisible]);

    useEffect(() => {
        const dropdownElement = document.getElementById(linkInfo.link);
        if (dropdownElement) {
            if (isDropdownVisible) {
                dropdownElement.classList.add('dropdown-active');
            } else {
                dropdownElement.classList.remove('dropdown-active');
            }
        }
    }, [isDropdownVisible, linkInfo.link]);

    return (
        <div ref={dropdownRef} id={linkInfo.link}>
            <div className='dropdown-desktop'>
                <div className="dropdown-parent" onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                    <div className='dropdown-top'>
                        <NavLink to={linkInfo.link} className="full">{linkInfo.name}</NavLink>
                        <div className="dropdown-arrow"></div>
                    </div>
                    {isDropdownVisible && (
                        <ul className="dropdown-menu">
                            {linkInfo.dropdownItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 && <hr />}
                                    <li className="dropdown-menu-item" id={item.link}>
                                        <NavLink to={item.link} className="dropdown-menu-item-link links-mobile2">{item.name}</NavLink>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <div className='dropdown-mobile' onClick={showDropdown}>
                <div className='dropdown-top'>
                    <div className='dropdown-mobile-title'>{linkInfo.name}</div>
                    <div className="dropdown-arrow"></div>
                </div>
                <ul className={getDropdownMenuClass()} id='ddmenu-mobile'>
                    <div className='backbutton-dropdown' onClick={(event) => hideDropdown(event)}>
                        <ArrowBackIcon style={{ fontSize: '30px' }} />
                    </div>
                    <NavLink to={linkInfo.link} className="full mobile-main-dropdown" onClick={(event) => hideDropdown(event)}>{linkInfo.name}</NavLink>
                    {linkInfo.dropdownItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && <hr />}
                            <li className="dropdown-menu-item" id={item.link}>
                                <NavLink to={item.link} className="dropdown-menu-item-link" onClick={(event) => hideDropdown(event)}>{item.name}</NavLink>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default NavbarDropdown;
