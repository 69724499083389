import React from "react";
import { Link } from "react-router-dom";


function FooterLink({ link, name, onClick }) {
  return (
    <Link onClick={onClick} to={`${link}`}>
      <li>{name}</li>
    </Link>
  );
}

export default FooterLink;
