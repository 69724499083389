import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import NavLinkX from './NavLink';

function NavbarItemMode({ linkInfo }) {
  if (linkInfo.mode === 'Dropdown') {
    return <NavbarDropdown linkInfo={linkInfo} />;
  } else if (linkInfo.mode === 'Link') {
    return <NavLinkX linkInfo={linkInfo} />;
  }
}

export default NavbarItemMode;
