import React from "react";
import { useLocation, NavLink } from "react-router-dom";

function NavLinkX({ linkInfo, onClick }) {
  const location = useLocation();
  const isActive = location.pathname === linkInfo.link;

  return (
    <div id={linkInfo.link}>
      <NavLink to={linkInfo.link} onClick={onClick} className={`full links-mobile ${isActive ? 'active' : ''}`} id={`${linkInfo.actual ? "actual" : ""}`}>
        <li className="nav-item">
          <span>{linkInfo.name}</span>
        </li>
      </NavLink>
    </div>
  );
}

export default NavLinkX;