import React from 'react'

export default function Loading() {
  return (
    <section className="load-container">
    <div>
        <div>
            <span className="one e6"></span>
            <span className="two e3"></span>
        </div>
    </div>

    <div>
        <div>
            <span className="one e1"></span>
            <span className="two e4"></span>
        </div>
    </div>

    <div>
        <div>
            <span className="one e5"></span>
            <span className="two e2"></span>
        </div>
    </div>
</section>
  )
}
