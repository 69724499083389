
import React from "react";
import { useTranslation } from 'react-i18next';

/////////////////////////////////////////////
//CSS
import "../Modules/presentation/Presentation.css";
import "../Modules/vitrine/Vitrine.css";
import "../Modules/map/Map.css";
import "../Modules/chiffre/Chiffre.css";
import "../Modules/domain/Domain.css";
import "./css/Home.css";

/////////////////////////////////////////////
//Components
import Vitrine from "../Modules/vitrine/Vitrine";
import Presentation from "../Modules/presentation/Presentation";
import Map from "../Modules/map/Map";
import Chiffres from "../Modules/chiffre/Chiffre";
import Banner from "../Modules/banner/Banner";
import Domain from "../Modules/domain/Domain";
import Partners from "../Modules/partners/Partners";

//////////////////////////////////////////////////////////////////////////////////////////

function Home() {
  document.title = "Plassys BESTEK";
  const { t } = useTranslation();
  return (
    <>
      <div className="container-home">
        <Vitrine />
        <Presentation />
        <Map />
        <Chiffres />
        <Banner
          imagelogo="qutek"
          imagebg="qutek.jpg"
          title={t('quantum.banner')}
          link="quantum"
          name={t('quantum.discover')}
        />
        <Domain />
        <Banner
          imagelogo="diamtek"
          imagebg="Diamtek3.jpg"
          title={t('diamond.banner')}
          link="diamond"
          name={t('diamond.discover')}
        />
        <Partners />
      </div>
    </>
  );
}
export default Home;
