import React from 'react'

import "./QutekHWItem.css"

export default function QutekHWItem({image, title}) {
    return (
        <>
            <div className="qutekHWItem-card" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/assets/${image})`}}>
                <h1 className='qutekHWItem-title'>{title}</h1>
            </div>
        </>
    )
}
