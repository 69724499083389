/////////////////////////////////////////////
//Dependencies
import React from "react";

/////////////////////////////////////////////
//CSS


/////////////////////////////////////////////
//Components
import Sitemap from "../Modules/sitemap/Sitemap";

//////////////////////////////////////////////////////////////////////////////////////////

function SMap() {
  document.title = "Plassys ▪ Site map";

  return (
    <>
      <Sitemap />
    </>
  );
}
export default SMap;
