import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Lang.css";

function Lang() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // detect the current browser language
    const detectedLang = navigator.language.split('-')[0]; // fr-FR => fr

    // change the language of the app to the detected language
    i18n.changeLanguage(detectedLang);

    // update the lang attribute of the html element
    document.documentElement.lang = detectedLang;

    console.log("Langue navigateur :", detectedLang);
    console.log("Langue par défaut :", i18n.language);
  }, [i18n]);

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    i18n.changeLanguage(newLanguage);

    // update the lang attribute of the html element
    document.documentElement.lang = newLanguage;

    console.log("Langue changée :", newLanguage);
  };

  return (
    <div className="lang-container">
      <button className="lang-button" onClick={toggleLanguage}>
        {i18n.language === "en" ? "FR" : "EN"}
      </button>
    </div>
  );
}

export default Lang;