/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

/////////////////////////////////////////////
//CSS
import "./css/Company.css";

/////////////////////////////////////////////
//Components
import ParseText from "../Modules/parseText/ParseText"

//////////////////////////////////////////////////////////////////////////////////////////

export default function Presentation() {
  const { t } = useTranslation();
  document.title = "Plassys ▪ " + t("navbar.Company")

  return (
    <div className="comp-container-page">
      <div className="comp-bg-top">
      </div>

      <div className="comp-valeurs">
        <div className="comp-card">
          <div className="comp-card-content">
            <h1 className="comp-card-title">{t("company.point.value.title")}</h1>
            <p className="comp-card-text">
              {t("company.point.value.text")}
            </p>
            <div className="spacer"></div>
          </div>
        </div>

        <div className="comp-card">
          <div className="comp-card-content">
            <h1 className="comp-card-title">{t("company.point.mission.title")}</h1>
            <p className="comp-card-text">
              {t("company.point.mission.text")}
            </p>
            <div className="spacer"></div>
          </div>
        </div>

        <div className="comp-card">
          <div className="comp-card-content">
            <h1 className="comp-card-title">{t("company.point.vision.title")}</h1>
            <p className="comp-card-text">
              {t("company.point.vision.text")}
            </p>
            <div className="spacer"></div>
          </div>
        </div>

      </div>


      <div className="comp-content-container">

        <div className="comp1">
          <div className="comp-content">
            <div className="comp-content-img">
              <img src="img/assets/bureaux.jpg" alt="Description" />
            </div>
            <div className="comp-content-text">
                <ParseText classN={"comp-content-anim"} text={t("company.text")} />
                <ParseText classN={"comp-content-anim"} text={t("company.text2")} />
            </div>
          </div>
        </div>
        {/* <div className="comp2">
          <div className="comp-content">
            <div className="comp-content-text">
                <ParseText text={t("company.text2")} />
            </div>
            <div className="comp-content-img">
              <img src="img/machines/machine4.png" alt="Description" />
            </div>
          </div>
        </div> */}

      </div>
      <div className="comp-discover">
        <ParseText classN="comp-discover-title" text={t("company.discover")} />
        <NavLink to="/categories" className="comp-discover-link">
          <button className="comp-discover-button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
            </svg>
            <div className="comp-discover-button-text">
              {t("navbar.Systems")}
            </div>
          </button>
        </NavLink>      
      </div>

    </div>
  );
}