import React, { useState, useEffect } from 'react';
import './cookiepopup.css';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const CookiePopup = () => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [accepted, setAccepted] = useState(false);

    function checkCookie(name) {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
        else return null;
    }

    function loadScript(scriptContent) {
        const script = document.createElement('script');
        script.textContent = scriptContent;
        document.head.appendChild(script);
    }

    function loadExternalScript(src) {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.head.appendChild(script);
    }

    useEffect(() => {
        const consent = checkCookie('cookie-consent');
        if (!consent) {
            setShowPopup(true);
        } else {
            setAccepted(true);
        }
    }, []);

    const handleRefuse = () => {
        document.cookie = "cookie-consent=refused; max-age=15768000 ; path=/"; //Cookie expires in 6 months (15768000sec) (GDPR)
        setShowPopup(false);
        setAccepted(false);

    };

    const handleAccept = () => {
        document.cookie = "cookie-consent=accepted; max-age=15768000 ; path=/"; //Cookie expires in 6 months (15768000sec) (GDPR)
        setShowPopup(false);
        setAccepted(true);

        // Google Analytics Localhost
        loadExternalScript('https://www.googletagmanager.com/gtag/js?id=G-3STHR1JWXT');
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-3STHR1JWXT');

        // Google Analytics DevNgrok
        loadExternalScript('https://www.googletagmanager.com/gtag/js?id=G-7J8WR7299Y');
        window.dataLayer = window.dataLayer || [];
        function gtagNG() { window.dataLayer.push(arguments); }
        gtagNG('js', new Date());
        gtagNG('config', 'G-7J8WR7299Y');


        loadScript(`
        // Microsoft Clarity
        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "mq5ijmgbh6");
    `);
    };

    if (!showPopup) {
        return null;
    }

    return (
        <div className="cookie-popup">
            <div>
                <p>{t('popupcookie.text1')}</p>
                <p>{t('popupcookie.text2')} <Link to="/privacypolicy">{t('popupcookie.link')}</Link>.</p>
            </div>
            <div className='button-container-popup'>
                <button className="btn-reject" onClick={handleRefuse}>{t('popupcookie.button2')}</button>
                <button className="btn-accept" onClick={handleAccept}>{t('popupcookie.button')}</button>
            </div>
        </div>
    );
};

export default CookiePopup;