import React from "react";

import "./ContactInfo.css";

export default function ContactInfo({ flag, country, name, address, contact, mail, phone }) {
    return (
        <div className="contact-info-card">
            {country && (
                <div className="contact-info-country">
                    <img src={`../img/icones/i-flag-${flag}.png`} alt={flag}/>
                    <h2>{country}</h2>
                </div>
            )}
            {name && (
                <div className="info">
                    <img src="../img/icones/i-build.png" alt="name" />
                    <label>
                        {name}
                    </label>
                </div>
            )}
            {address && (
                <div className="info">
                    <img src="../img/icones/i-gps.png" alt="address" />
                    <label>
                        {address}
                    </label>
                </div>
            )}
            {contact && (
                <div className="info">
                    <img src="../img/icones/i-man.png" alt="contact" />
                    <label>
                        {contact}
                    </label>
                </div>
            )}
            {mail && (
                <div className="info">
                    <img src="../img/icones/i-mail.png" alt="mail" />
                    <a href={`mailto:${mail}`}>
                        <label className="cursor">
                            {mail}
                        </label>
                    </a>
                </div>
            )}
            {phone && (
                <div className="info">
                    <img src="../img/icones/i-phone.png" alt="phone" />
                    <a href={`tel:${phone}`}>
                        <label className="cursor">
                            {phone}
                        </label>
                    </a>
                </div>
            )}
        </div>
    );
}