import React from 'react'

import "./BannerDomain.css"

export default function BannerDomain({logo, text, imagebg}) {
    return (
        <>
            <div className="bd-bg-top" style={{backgroundImage: `url(img/assets/${imagebg}`}}>
                <div className="bd-bg-top-top">
                    <img className="bd-bg-top-img" src={`./img/logo/${logo}.png`} alt={logo} />
                </div>
                <div className="bd-bg-top-bottom">
                    <h1 className='bd-bg-top-text'>{text}</h1>
                </div>
            </div>
        </>
    )
}
