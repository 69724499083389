/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//CSS
import "./css/Distributors.css";

/////////////////////////////////////////////
//Components
import ContactInfo from "../Modules/contactInfo/ContactInfo";

/////////////////////////////////////////////
export default function Distributors() {
    const { t } = useTranslation();
    document.title = "Plassys ▪ " + t("navbar.Contact");


    return (
        <div className="distrib-container">
            <h1 className="title-contact">CONTACT</h1>
            <div className="contact-container-distrib">
                <h1 className="distrib-title">Distributors</h1>

                <div className="contact-distrib-list">
                    <ContactInfo flag="uk" country={t("contact.distributor.uk")} name="DaLEK Software Ltd." address="2 Tweed Drive – Bearsden G61 1EJ – Glasgow" contact="Mr. David LOOMES" mail="davidl@daleksw.com" phone="+44 1419 4240 54" />
                    <ContactInfo flag="germany" country={t("contact.distributor.germany")} name="EpiServe GmbH" address="10, Steindorfstrasse D-80538 München" contact="Mr. Rudolf LENZ" mail="info@episerve.de" phone="+49 89 30 63 50-0" />
                    <ContactInfo flag="austria" country={t("contact.distributor.austria")} name="EpiServe GmbH" address="10, Steindorfstrasse D-80538 München" contact="Mr. Rudolf LENZ" mail="info@episerve.de" phone="+49 89 30 63 50-0" />
                    <ContactInfo flag="india" country={t("contact.distributor.india")} name="Pravartaka Infotechnates LLP" address="36/54, Surveyors Street, 2nd Cross, Basavanagudi, Bangalore – 560 00" contact="Mr. Sai RAM" mail="mail@pravartaka.com" phone="+91 9449 08 9449" />
                    <ContactInfo flag="singapore" country={t("contact.distributor.singapore")} name="Advantec Process Systems Pte. Ltd." address="11, Beach Road #03-01, Crasco Buidling 189675" contact="Mr. Han Chich Heng" mail="chihheng.han@advantec.com.sg" phone="+65 6242 4778" />
                    <ContactInfo flag="japan" country={t("contact.distributor.japan")} name="R-DEC Co.Ltd." address="1-16-10 Ninomiya Tsukuba,305-0051 Ibaraki" contact="Mr. Akira KAGAMI" mail="kagami@rdec.co.jp" phone="+81 29 858 0211" />
                    {/* <ContactInfo flag="china" country={t("contact.distributor.china")} name="CWOPTO Technology Co. Ltd." address="Room 1507, Building I, No.106, Zhongjiang Road, Putuo District, Shanghai 200062" contact="Mr. Wei XIAO" mail="info@cwopto.com" phone="+86-21-50477207" /> */}
                    <ContactInfo flag="china" country={t("contact.distributor.china")} name="BAOSON INDUSTRIAL LIMITED" address="Flat C, 9/F, Winning House, NO.72-74 Wing Lok Street, Sheung Wan, HK" mail="info@baoson.com.hk" phone="+86 152 17940271" />
                    <ContactInfo flag="hongkong" country="Hong Kong" name="BAOSON INDUSTRIAL LIMITED" address="Flat C, 9/F, Winning House, NO.72-74 Wing Lok Street, Sheung Wan, HK" mail="info@baoson.com.hk" phone="+86 152 17940271"  />
                    <ContactInfo flag="macau" country={t("contact.distributor.macau")} name="BAOSON INDUSTRIAL LIMITED" address="Flat C, 9/F, Winning House, NO.72-74 Wing Lok Street, Sheung Wan, HK" mail="info@baoson.com.hk" phone="+86 152 17940271"  />
                    <ContactInfo flag="taiwan" country={t("contact.distributor.taiwan")} name="Scientek Corp." address="3F, N°3, Jinggong Rd., Sungfong Township Hsinchu County, 30443" contact="Mr. Frank LU" mail="frankl@scientek.com.tw" phone="+886-3-5590909,,6463#" />


                </div>

            </div>
        </div>
    )
}
