import React, { useState } from "react";

function NavActivbar({ navLinks }) {
  const [activeBarStyle, setActiveBarStyle] = useState({
    visibility: "visible",
    width: 0,
    left: 0,
    transition: "",
  });

return <div className="active-bar" style={activeBarStyle}></div>;

}

export default NavActivbar;