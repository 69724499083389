import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductAdd from "./ProductCatAdd";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

export default function ProductsCat() {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const categories = t("category", { returnObjects: true });

  const normalizeString = (str) => str.replace(/\s|-/g, '').toLowerCase();

  const clearSearch = () => {
    setSearchTerm('');
  };

  const filteredCategories = Object.entries(categories)
    .map(([key, categoryData]) => {
      const searchKeywords = searchTerm.toLowerCase().split(' ').map(normalizeString);

      const matches = (text) => {
        const normalizedText = normalizeString(text);
        return searchKeywords.every(keyword => normalizedText.includes(keyword));
      };

      // Correspondance flexible pour les tags : chaque mot-clé doit correspondre à n'importe quel tag
      const tagMatches = categoryData.tags && searchKeywords.every(keyword =>
        categoryData.tags.some(tag => normalizeString(tag).includes(keyword))
      ) ? 2 : 0;

      const nameMatches = categoryData.name && matches(categoryData.name) ? 3 : 0;
      const descMatches = categoryData.desc && matches(categoryData.desc) ? 1 : 0;

      const score = nameMatches + tagMatches + descMatches;

      return { key, categoryData, score };
    })
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score);

  filteredCategories.forEach(({ categoryData, score }) => {
    console.log(`Category: ${categoryData.name}`);
    console.log(`Score: ${score}`);
    console.log('---');
  });

  console.log(filteredCategories.map(({ key, categoryData }) => ({ key, name: categoryData.name })));





  return (
    <div className="products-container">
      <h1 className="title-productCat">{t('categoryPage.title')}</h1>

      <div className="search-bar-container">
        <input
          type="text"
          className="search-bar-item"
          placeholder={t('categoryPage.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm ? (
          <ClearIcon
            className="search-icon"
            onClick={clearSearch}
          />
        ) : (
          <SearchIcon className="search-icon" />
        )}
      </div>


      {filteredCategories.length > 0 ? (
        <section className="articles cat">
          {filteredCategories.map(({ key, categoryData }, index) => (
            <ProductAdd
              key={key}
              name={categoryData.name}
              link={categoryData.link}
              tags={categoryData.tags}
              image={categoryData.image}
              desc={categoryData.catch}
            />
          ))}
        </section>
      ) : (
        <h2 className="noResults">{t('notfound.category')}</h2>
      )}
    </div>
  );
}
