import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MachineAdd from "../Modules/machines/MachineAdd";
import ParseText from "../Modules/parseText/ParseText";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


/////////////////////////////////////////////
//CSS
import "./css/GetMachines.css";

export default function GetMachines() {
  const [searchTerm, setSearchTerm] = useState("");
  const [machines, setMachines] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState(!isMobile);

  const { categoryName } = useParams();
  const formattedCategoryName = categoryName.replace(/\s+/g, '-');

  useEffect(() => {
    const machinesData = t("machines", { returnObjects: true });
    const machinesInCategory = Object.values(machinesData).filter(machine =>
      machine.tags && machine.tags.includes(categoryName)
    );
    setMachines(machinesInCategory);
  }, [categoryName, t]);

  useEffect(() => {
    setExpanded(!isMobile);
  }, [location, isMobile]);

  document.title = `Plassys ▪ ${categoryName || ''}`;

  const handleGoBack = () => {
    navigate(`/categories`);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }

  const renderLists = () => {
    const categoryData = t(`category.${categoryName}`, { returnObjects: true });
    const { list } = categoryData;

    if (list && list.list1 && list.list2) {
      return (
        <div className="lists-container-productCat">
          <div className="list">
            <h3>{list.list1.title}</h3>
            <ul>
              {list.list1.elt.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="qutek-annexe-line"></div>
          <div className="list">
            <h3>{list.list2.title}</h3>
            <ul>
              {list.list2.elt.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }

    return null;
  };

  const normalizeString = (str) => str.replace(/\s|-/g, '').toLowerCase();

  const filteredMachines = Object.entries(machines)
    .map(([key, machineData]) => {
      const searchKeywords = searchTerm.toLowerCase().split(' ').map(normalizeString);

      const matches = (text) => {
        const normalizedText = normalizeString(text);
        return searchKeywords.every(keyword => normalizedText.includes(keyword));
      };

      // Correspondance flexible pour les tags : chaque mot-clé doit correspondre à n'importe quel tag
      const tagMatches = machineData.tags && searchKeywords.every(keyword =>
        machineData.tags.some(tag => normalizeString(tag).includes(keyword))
      ) ? 2 : 0;

      const nameMatches = machineData.name && matches(machineData.name) ? 3 : 0;
      const descMatches = machineData.desc && matches(machineData.desc) ? 1 : 0;

      const score = nameMatches + tagMatches + descMatches;

      return { key, machineData, score };
    })
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score);

  filteredMachines.forEach(({ machineData, score }) => {
    console.log(`Machine: ${machineData.name}`);
    console.log(`Score: ${score}`);
    console.log('---');
  });

  console.log(filteredMachines.map(({ key, machineData }) => ({ key, name: machineData.name })));

  return (
    <div className="products-container">
      <div className="backbutton-container">
        <button className="backbutton" onClick={handleGoBack}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
          </svg>
          <div className="backbuttontext">{t('annexes.back-button')}</div>
        </button>
      </div>

      <div className="intro-container">
        <div className='intro-top'>
          <div className='intro-top-left'>
            <div className='intro-top-left-titles'>
              <h1 className='intro-top-left-title'>{t(`category.${categoryName}.name`)}</h1>
              <h2 className='intro-top-left-subtitle'>{t(`category.${categoryName}.catch`)}</h2>
            </div>
            <i><ParseText classN="text-productCat centertxt" text={t(`categoryPage.disclamer`)} /></i>
            <center className="devise-productCat">{t(`categoryPage.devise`)}</center>

          </div>
          <div className='intro-top-right' style={{ backgroundImage: `url(../../img/assets/${formattedCategoryName}-cover.jpg)` }}>
          </div>
        </div>
      </div>

      <div className="header-productCat">

        <Accordion
          defaultExpanded={!isMobile}
          className='accordion-item accordion-productCat'
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: '3rem' }} />}
            className='accordion-title'
          >
            {t(`categoryPage.info`)}
          </AccordionSummary>
          <AccordionDetails>
            <ParseText text={t(`category.${categoryName}.desc`)} classN="text-productCat" />
            {renderLists()}

          </AccordionDetails>
        </Accordion>

      </div>

      <div className="search-bar-container space-search">
        <input
          type="text"
          className="search-bar-item"
          placeholder={`${t('categoryPage.search')} - ${t(`category.${categoryName}.name`)}`}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {searchTerm ? (
          <ClearIcon
            className="search-icon"
            onClick={clearSearch}
          />
        ) : (
          <SearchIcon className="search-icon" />
        )}
      </div>


      {filteredMachines.length > 0 ? (
        <section className="articles" id="input-result-zone">
          {filteredMachines.map((machine, index) => (
            <MachineAdd
              key={index}
              cat={categoryName}
              name={machine.machineData.name}
              desc={machine.machineData.desc2}
              image={machine.machineData.image && machine.machineData.image.length > 0 ? machine.machineData.image[0] : ''}
              link={machine.machineData.link}
            />
          ))}
        </section>
      ) : (
        <h2 className="noResults">{t('notfound.machine')}</h2>
      )}
    </div>
  );

}
