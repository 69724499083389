import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./FooterLink.jsx";
import FooterLink from "./FooterLink.jsx";
import FooterTitle from "./FooterTitle.jsx";
import { Link } from "react-router-dom";


function Footer() {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (sectionId) => {
    // Fermer toutes les sections
    const newOpenSections = Object.keys(openSections).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    newOpenSections[sectionId] = !openSections[sectionId];

    setOpenSections(newOpenSections);
  };

  return (
    <footer className="footer">

      <div className="mobile-container-footer">

        <div className="footer-section-first">
          <div className="title-head" onClick={() => toggleSection('section1')}>
            <FooterTitle
              title={t('footer.title2')}
              onClick={() => toggleSection('section1')}
              isOpen={openSections['section1']}
            />
            <div className="arrow" >
              {openSections['section1'] ? '▼' : '►'}
            </div>
          </div>

          {openSections['section1'] && (
            <div className="text-footer">
              <FooterLink onClick={toggleSection} link="/presentation" name={t('navbar.Company')} />
              <FooterLink onClick={toggleSection} link="/diamond" name={t('diamond.name')} />
              <FooterLink onClick={toggleSection} link="/quantum" name={t('navbar.Quantum')} />
              <FooterLink onClick={toggleSection} link="/categories" name={t('navbar.Systems')} />
            </div>
          )}
        </div>

        <div className="footer-section">
          <div className="title-head" onClick={() => toggleSection('section4')}>
            <FooterTitle
              title={t('footer.title3')}
              onClick={() => toggleSection('section4')}
              isOpen={openSections['section4']}
            />
            <div className="arrow" >
              {openSections['section4'] ? '▼' : '►'}
            </div>
          </div>

          {openSections['section4'] && (
            <div className="text-footer">
              <FooterLink onClick={toggleSection} link="/contact?service=global" name="Global" />
              <FooterLink onClick={toggleSection} link="/contact?service=technical" name={t('navbar.ContactDD.Technical')} />
              <FooterLink onClick={toggleSection} link="/contact?service=commercial" name="Commercial" />
              <FooterLink onClick={toggleSection} link="/contact/distributors" name={t('navbar.ContactDD.Distributor')} />
            </div>
          )}
        </div>

        <div className="footer-section-last">
          <div className="title-head" onClick={() => toggleSection('section5')}>
            <FooterTitle
              title={t('footer.title4')}
              onClick={() => toggleSection('section5')}
              isOpen={openSections['section5']}
            />
            <div className="arrow" >
              {openSections['section5'] ? '▼' : '►'}
            </div>
          </div>

          {openSections['section5'] && (
            <div className="text-footer">
              <FooterLink onClick={toggleSection} link="/sitemap" name={t('footer.link4-1')} />
              <FooterLink onClick={toggleSection} link="/gallery" name={t('footer.link4-2')} />
              <FooterLink onClick={toggleSection} link="/recruitment" name={t('footer.link4-3')} />
              <FooterLink onClick={toggleSection} link="https://www.linkedin.com/company/plassys-bestek/" name={t('footer.link4-4')} />
              <Link onClick={toggleSection} to="/presentation"><li>{t('footer.linkbot1')}</li></Link>
              <Link onClick={toggleSection} to="/privacypolicy"><li>{t('footer.linkbot5')}</li></Link>

            </div>
          )}
        </div>

        <div className="footer-bottom">
          <div className="container">

            <div className="line-footer"></div>

            <Link to="/">
              <div className="logo-container">
                <div className="logo-container-img"></div>
              </div>
            </Link>

            <div className="line-footer"></div>

            <div className="copyright">
              COPYRIGHT &copy; 2024 PLASSYS BESTEK
            </div>

          </div>
        </div>
      </div>


      <div className="container-footer">
        <div className="grid-container-footer">

          <FooterTitle title={t('footer.title2')} />
          <FooterTitle title={t('footer.title3')} />
          <FooterTitle title={t('footer.title4')} />
          <div className="text-footer">
            <FooterLink link="/presentation" name={t('navbar.Company')} />
            <FooterLink link="/diamond" name={t('diamond.name')} />
            <FooterLink link="/quantum" name={t('navbar.Quantum')} />
            <FooterLink link="/categories" name={t('navbar.Systems')} />
          </div>
          <div className="text-footer">
            <FooterLink link="/contact?service=Global" name="Global" />
            <FooterLink link="/contact?service=Technical" name={t('navbar.ContactDD.Technical')} />
            <FooterLink link="/contact?service=Commercial" name="Commercial" />
            <FooterLink link="/contact/distributors" name={t('navbar.ContactDD.Distributor')} />
          </div>
          <div className="text-footer">
            <FooterLink link="/sitemap" name={t('footer.link4-1')} />
            <FooterLink link="/gallery" name={t('footer.link4-2')} />
            <FooterLink link="/recruitment" name={t('footer.link4-3')} />
            <FooterLink link="https://www.linkedin.com/company/plassys-bestek/" name={t('footer.link4-4')} />
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">

            <div className="line-footer"></div>

            <Link to="/">
              <div className="logo-container">
                <div className="logo-container-img"></div>
              </div>
            </Link>
            <div className="liens-container">
              <Link to="/presentation"><li>{t('footer.linkbot1')}</li></Link>
              <li className="barre"></li>
              <Link to="/contact"><li>{t('footer.linkbot2')}</li></Link>
            </div>


            <div className="line-footer"></div>

            <div className="copyright">
              COPYRIGHT &copy; 2024 PLASSYS BESTEK
            </div>

            <div className="liens-container2">
              <Link to="/privacypolicy"><li>{t('footer.linkbot5')}</li></Link>
            </div>

          </div>
        </div>
      </div>


    </footer >
  );
}


export default Footer;
