/////////////////////////////////////////////
//Dependencies
import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Alert from '@mui/material/Alert';

/////////////////////////////////////////////
//CSS
import "./css/Contact.css";

/////////////////////////////////////////////
//Components
import ContactInfo from "../Modules/contactInfo/ContactInfo";
import Loading from '../Modules/loading/Loading';

//////////////////////////////////////////////////////////////////////////////////////////

export default function Contact() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('Global');
  const [selectedCountry, setSelectedCountry] = useState('Other');
  const [machineName, setMachineName] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '', variant: '' });
  document.title = "Plassys ▪ " + t("navbar.Contact");
  const [formData, setFormData] = useState({ service: 'Global', country: "Other", civility: 'Mr', lastname: '', firstname: '', company: '', address: '', phone: '', email: '', object: '', message: '' });
  const countryList = [
    { name: "Other", key: "contact.distributor.other" },
    { name: "France", key: "contact.distributor.france" },
    { name: "Uk", key: "contact.distributor.uk" },
    { name: "Germany", key: "contact.distributor.germany" },
    { name: "Austria", key: "contact.distributor.austria" },
    { name: "India", key: "contact.distributor.india" },
    { name: "Singapore", key: "contact.distributor.singapore" },
    { name: "Japan", key: "contact.distributor.japan" },
    { name: "China", key: "contact.distributor.china" },
    { name: "Hong Kong", key: "Hong Kong" },
    { name: "Macau", key: "contact.distributor.macau" },
    { name: "Taiwan", key: "contact.distributor.taiwan" }
  ];
  const machineNameList = [
    "MEB 300 S",
    "MEB 550 S",
    "MEB 550 S 4x4",
    "MEB 550 SL3 UHV",
    "MEB 600 S",
    "MEB 650",
    "MEB 700 SL",
    "MEB 700 SL4 UHV",
    "MEB 800 S2-III UHV",
    "MEB 800 SL",
    "SQUID6 UHV",
    "MP 450 S",
    "MP 650 B",
    "MP 650 S",
    "MP 700 S",
    "MP 700 S UHV",
    "MP 900 S",
    "MP 1000 S",
    "ME 450",
    "ME 450 S-IN",
    "ME 600 SL",
    "SSDR 150",
    "SSDR 400",
    "MU 400",
    "MU 700",
    "MPE 600 S",
    "MU 600 S",
    "MG 200",
    "MG 200 S",
    "MG 300",
    "MG 300 S",
    "MG 350",
    "DPA 70 RF",
    "DS 400 BF",
    "MDS 130",
    "MDS 130 RF",
    "MDS 150",
    "MDS 150 G",
    "MDS 150 RF",
    "MDS 250",
    "MGDS 150",
    "SP 500",
    "MEB 300",
    "MEB 300 S HV",
    "MEB 400",
    "MEB 400 S",
    "MEB 400 SL",
    "MEB 450",
    "MEB 450 S",
    "MEB 450 2S",
    "MEB 450 SL UHV",
    "MEB 500",
    "MEB 500 SL",
    "MEB 550",
    "MEB 550 S -6\"",
    "MEB 550 B",
    "MEB 550 S2-I HV",
    "MEB 550 S2-I UHV",
    "MEB 550 S2-III UHV",
    "MEB 550 S3",
    "MEB 550 S4-I UHV",
    "MEB 550 S4-II UHV",
    "MEB 550 SL",
    "MEB 550 SL UHV",
    "MEB 550 SL4 UHV",
    "MEB 600",
    "MEB 600 SL UHV",
    "MEB 650 S",
    "MEB 700 S HV",
    "MEB 700 S2-I UHV",
    "MEB 700 S2-II UHV",
    "MEB 800",
    "MEB 800 S",
    "MEB 800 S2-III",
    "MEB 800 SL4 UHV",
    "MEB 1100 S",
    "PCB 400",
    "SQUID 5 UHV",
    "BC 300",
    "BCT 500",
    "MEP 300",
    "MEPS 300",
    "MP 200 S",
    "MP 250",
    "MP 260",
    "MP 300",
    "MP 300 S",
    "MP 320",
    "MP 350",
    "MP 350 S",
    "MP 400",
    "MP 400 S",
    "MP 450",
    "MP 500",
    "MP 500 S",
    "MP 500 S3",
    "MP 550",
    "MP 550 S",
    "MP 550 S2",
    "MP 600",
    "MP 600 S UHV",
    "MP 700 S HV",
    "MP 800 S",
    "MP 800 S2 UHV",
    "MP 850",
    "MP 900",
    "MP 1000 SL",
    "MPF 400",
    "MPL 400 B",
    "MPU 600 S",
    "OSCAR 450",
    "PROD 400",
    "ME 200",
    "ME 300",
    "ME 300 B",
    "ME 320",
    "ME 400",
    "ME 400 B",
    "ME 550 B",
    "ME 600 HVS",
    "MPC 400",
    "MPC 450",
    "MPC 500",
    "MPC 800",
    "MPC 800 RF",
    "MPC 1000",
    "BJS 150",
    "CVD 200",
    "CVD 300",
    "CVD 300 S",
    "CVD 400",
    "CVD 450",
    "CVD 500",
    "CVD 700",
    "DCS-HF",
    "D-TEK 210",
    "D-TEK 210-UHP",
    "MW 150",
    "PECVD",
    "PECVD 500",
    "PECVD III-V",
    "PP 300",
    "SSDR 150-R",
    "SSDR 915",
    "MU 350",
    "MU 450",
  ];

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);


  // Get the service parameter from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serviceParam = params.get('service');
    setSelectedOption(serviceParam || 'Global');
  }, [location]);

  const textareaRef = useRef(null);

  const handleInputChange = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleInputChange();
  }, []);


  /********************************************/
  /* Send email form */

  const sendEmail = async () => {
    const waitTime = localStorage.getItem('waitTime');
    const currentTime = new Date().getTime();

    if (waitTime && currentTime < parseInt(waitTime, 10)) {
      const remainingTime = Math.round((parseInt(waitTime, 10) - currentTime) / 60000);
      setAlert({ type: 'warning', message: `${t('annexes.waitSubmit')} (${remainingTime} min).`, variant: 'filled' });
      setIsDisabled(false);
      setLoading(false);
      return;
    }

    const dataToSend = {
      ...formData,
      currentTime: new Date().toISOString() //compatibility with the server
    };

    try {
      const response = await fetch('http://localhost:3555/contactformmailplassys', {
        method: 'POST',
        body: JSON.stringify(dataToSend),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      // console.log("réponse serveur : ", response);
      setAlert({ type: 'success', message: t('annexes.success') });
      setFormSubmitted(true);
      // Reset the form
      setFormData({ service: 'Global', country: 'Other', civility: 'Mr', lastname: '', firstname: '', company: '', address: '', phone: '', email: '', object: '', message: '', machineName: '', serialNumber: '' });
      setSelectedOption('Global');
      setSelectedCountry('Other');
      setMachineName('');
      setSerialNumber('');
      setCaptchaValue(null);
      handleInputChange();
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    } catch (error) {
      // console.error('Error send form :', error);
      // console.log(JSON.stringify(formData));
      setAlert({ type: 'error', message: t('annexes.error'), variant: 'filled' });
    } finally {
      setIsDisabled(false);
      setLoading(false);
      const newWaitTime = currentTime + (5 * 60 * 1000); // 5 min cooldown
      localStorage.setItem('waitTime', newWaitTime.toString());
    }
  }

  // Handle form submit
  async function handleSubmit(e) {
    setIsDisabled(true);
    setLoading(true);
    e.preventDefault();
    setFormData({ ...formData, service: selectedOption });
    if (validateForm()) {
      try {
        await sendEmail(formData);
      } catch (error) {
        setAlert({ type: 'error', message: t('annexes.error'), variant: 'filled' });
      } finally {
        setIsDisabled(false);
        setLoading(false);
      }
    } else {
      setIsDisabled(false);
      setLoading(false);
    }
  }


  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formData.lastname || !formData.firstname || !formData.company || !formData.email || !formData.message) {
      setAlert({ type: 'warning', message: t('annexes.warning'), variant: 'filled' });
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setAlert({ type: 'warning', message: t('annexes.invalidMail'), variant: 'filled' });
      return false;
    }
    if (formData.phone && !/^\+?[0-9]{10,15}$/.test(formData.phone)) {
      setAlert({ type: 'warning', message: t('annexes.invalidTel'), variant: 'filled' });
      return false;
    }
    if (!captchaValue) {
      setAlert({ type: 'warning', message: t('annexes.invalidCaptcha'), variant: 'filled' });
      return false;
    }
    return true;
  };


  /********************************************/

  return (
    <>
      <div className="contact-container">
        {loading && <Loading />}
        <h1 className="title-contact">CONTACT</h1>
        <div className="contact-body">

          <div className="contact-form">
            <div className="contact-form-title">{t("contact.form.title")}</div>

            <form className="contact-form-content" onSubmit={handleSubmit}>

              {/* Service And Country*/}
              <div className="form-group form-group-select">
                <div>
                  <label className="form-label">Service* :</label>
                  <select name="service" required value={selectedOption} onChange={e => { setSelectedOption(e.target.value); handleChangeFormData(e); }}>
                    <option value="Global">Global</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Technical">{t("contact.form.service.technical")}</option>
                  </select>
                </div>
                {selectedOption === 'Commercial' && (
                  <div>
                    <label className="form-label">{t("contact.form.country")}* :</label>
                    <select name="country" required value={selectedCountry} onChange={e => { setSelectedCountry(e.target.value); handleChangeFormData(e); }}>
                      {countryList.map((country, index) => (
                        <option key={index} value={country.name}>
                          {t(country.key)}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {selectedOption === 'Technical' && (
                  <>
                    <div>
                      <label className="form-label">{t("contact.form.machineName")}* :</label>
                      <input
                        type="text"
                        name="machineName"
                        list="machineNameList"
                        required
                        value={machineName}
                        onChange={e => { setMachineName(e.target.value); handleChangeFormData(e); }}
                      />
                      <datalist id="machineNameList">
                        {machineNameList.map((machine, index) => (
                          <option key={index} value={machine} />
                        ))}
                        <option value={t("contact.distributor.other") + " / " + t("navbar.SystemsDD.Customized")}>
                          {t("contact.distributor.other")} / {t("navbar.SystemsDD.Customized")}
                        </option>
                      </datalist>
                    </div>
                    <div>
                      <label className="form-label">{t("contact.form.serialNumber")}* :</label>
                      <input
                        type="text"
                        name="serialNumber"
                        required
                        value={serialNumber}
                        onChange={e => { setSerialNumber(e.target.value); handleChangeFormData(e); }}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* Contact Information */}
              <div className="form-group-row">

                <div className="form-group double-input">
                  <div className="double-input-civility full-select">
                    <label className="form-label">{t("contact.form.civility")}* :</label>
                    <select name="civility" required className="full-select" value={formData.civility} onChange={handleChangeFormData}>
                      <option value="Mr">{t("contact.form.civil.mr")}</option>
                      <option value="Mrs">{t("contact.form.civil.mrs")}</option>
                    </select>
                  </div>
                  <div className="double-input-extend full-select">
                    <label className="form-label">{t("contact.form.lastname")}* :</label>
                    <input name="lastname" required type="text" value={formData.lastname} onChange={handleChangeFormData} />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">{t("contact.form.firstname")}* :</label>
                  <input name="firstname" required type="text" value={formData.firstname} onChange={handleChangeFormData} />
                </div>
              </div>

              {/* Company */}
              <div className="form-group-row">
                <div className="form-group">
                  <label className="form-label">{t("contact.form.company")}* :</label>
                  <input name="company" required type="text" value={formData.company} onChange={handleChangeFormData} />
                </div>

                <div className="form-group">
                  <label className="form-label">{t("contact.form.address")} :</label>
                  <input name="address" type="text" value={formData.address} onChange={handleChangeFormData} />
                </div>
              </div>

              {/* Email and Phone */}
              <div className="form-group-row">

                <div className="form-group">
                  <label className="form-label">Email* :</label>
                  <input name="email" required type="email" value={formData.email} onChange={handleChangeFormData} />
                </div>
                <div className="form-group">
                  <label className="form-label">{t("contact.form.tel")} :</label>
                  <input name="phone" type="tel" value={formData.phone} onChange={handleChangeFormData} />
                </div>

              </div>

              {/* Object */}
              <div className="form-group">
                <label className="form-label">{t("contact.form.object")} :</label>
                <input name="object" type="text" value={formData.object} onChange={handleChangeFormData} />
              </div>

              {/* Message */}
              <div className="form-group">
                <label className="form-label">Message* :</label>
                <textarea
                  required
                  name="message"
                  rows="4"
                  ref={textareaRef}
                  value={formData.message}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleChangeFormData(e);
                  }}
                />
              </div>

              {/* Captcha and Send */}
              <div className="form-extra">
                <ReCAPTCHA
                  sitekey="6LdlbnkpAAAAAJ-7wZCc5R9Ywaxi_15y8g4UahwB"
                  onChange={value => setCaptchaValue(value)}
                />
                {captchaValue && (
                  <button className="contact-button" type="submit" disabled={isDisabled}>
                    <div className="svg-wrapper-1">
                      <div className="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <span>{t("contact.form.send")}</span>
                  </button>
                )}
              </div>

              {alert.type && <Alert severity={alert.type} variant={alert.variant}>{alert.message}</Alert>}

            </form>

          </div>

          <div className="contact-info-plassys">
            <div className="contact-info-title-plassys">
              PLASSYS BESTEK
            </div>
            <div className="contact-info-content-plassys">
              <div className="contact-info-coord-plassys">
                <ContactInfo
                  flag="france"
                  country={t("contact.country.france")}
                  name="PLASSYS"
                  address="14 Rue de la Gare, 91630 Marolles-En-Hurepoix"
                  contact="Mr. jean-Philippe"
                  mail="client@plassys.com"
                  phone="+331 23 45 67 89"
                />
                <div className="contact-info-image-plassys"></div>
              </div>
              <div className="contact-info-map-plassys">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10560.865073938554!2d2.292898!3d48.5674065!3m2!1i1024!2i768!4f899.1!3m3!1m2!1s0x47e5dafb6170081b%3A0xe3d05c7e5bcc2cea!2sPLASSYS!5e0!3m2!1sfr!2sfr!4v1698398990897!5m2!1sfr!2sfr&z=0&output=embed"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  className="GMap"
                  title="Google Map - PLASSYS BESTEK"
                ></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
