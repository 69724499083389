import React from "react";

function PartnersAdd({brand}) {
  return (

          <div className="marque-img-container">
            <img className="marque-img" src={`../img/marques/${brand}.png`} alt={brand} />
          </div>

  );
}

export default PartnersAdd;
