import React, { useState, useEffect } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./GalleryRollAdd.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

export default function GalleryRollAdd({ title, photoPaths, defaultExpanded=false }) {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const loadImages = async () => {
            const loadedPhotos = [];
            let loadedCount = 0;
            const totalImages = photoPaths.length;

            for (let i = 0; i < photoPaths.length; i++) {
                const photoPath = photoPaths[i];
                const img = new Image();
                img.src = photoPath;

                await new Promise((resolve) => {
                    img.onload = () => {
                        loadedPhotos.push({
                            src: img.src,
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                            title: photoPath.split("/").pop().split(".")[0].replace(/_/g, " "),
                            key: `${photoPath}-${i}`
                        });
                        loadedCount++;
                        console.log(`Loading progress: ${Math.round((loadedCount / totalImages) * 100)}%`);
                        resolve();
                    };

                    img.onerror = () => {
                        loadedCount++;
                        console.log(`Loading progress: ${Math.round((loadedCount / totalImages) * 100)}%`);
                        resolve(); // Resolve even if the image fails to load
                    };
                });
            }

            setImages(loadedPhotos);
        };

        loadImages();
    }, [photoPaths]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
            setHasBeenOpened(true);
        }
    };

    return (
        <>
            <Accordion
                defaultExpanded={defaultExpanded}
                className='accordion-item'
                expanded={expanded === 'panel1'}
                onChange={handleAccordionChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fontSize: '2rem' }} />}
                    className='accordion-title'
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container className='accordion-photo-container'>
                        {hasBeenOpened && (
                            <PhotoAlbum
                                photos={images}
                                layout="rows"
                                targetRowHeight={150}
                                onClick={({ index }) => {
                                    setPhotoIndex(index);
                                    setLightboxOpen(true);
                                }}
                                breakpoints={[600, 900, 1500]}
                            />
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {lightboxOpen ? (
                <Lightbox
                    slides={images.map(image => ({ src: image.src, title: image.title }))}
                    open={photoIndex >= 0}
                    index={photoIndex}
                    padding={10}
                    close={() => setPhotoIndex(-1)}
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            ) : null}
        </>
    );
}