/////////////////////////////////////////////
//Dependencies
import { React } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

// import WorkIcon from '@mui/icons-material/HomeWork';

import WorkIcon from '@mui/icons-material/TaskAlt';

/////////////////////////////////////////////
//CSS
import "./css/Quantum.css";
import "./css/VerticalTL.css";
// import 'react-vertical-timeline-component/style.min.css';

/////////////////////////////////////////////
//Components
import ParseText from '../Modules/parseText/ParseText';
import BannerDomain from '../Modules/bannerdomain/BannerDomain';
import HistoryDomain from '../Modules/historyDomain/HistoryDomain';
import QutekHWItem from '../Modules/qutekHWItem/QutekHWItem';
import GalleryRollAdd from '../Modules/galleryRoll/GalleryRollAdd';

//////////////////////////////////////////////////////////////////////////////////////////


export default function Quantum() {
  const { t } = useTranslation();
  document.title = "Plassys ▪ " + t("navbar.Quantum");

  const contentStyleImportant = {
    background: '#B52A7D',
    color: '#fff',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '10px',
    paddingLeft: '10px'
  };

  const contentStyle = {
    background: '#d88eba',
    color: '#fff',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '10px',
    paddingLeft: '10px'
  };

  const iconStyleImportant = {
    background: '#B52A7D',
    color: '#fff',
    display: 'none'
  };

  const iconStyle = {
    background: '#d88eba',
    color: '#fff',
    display: 'none'
  };

  const contentArrowStyleImportant = {
    borderRight: '7px solid  #B52A7D'
  };

  const contentArrowStyle = {
    borderRight: '7px solid  #d88eba'
  };

  return (
    <div className='qutek-container-page'>

      {/*********************************************************************************** 
        Modify BannerDomain :
        
        <BannerDomain
          logo="Logo of the domain"                                (Put image in /public/img/logo)
          text="Text for the domain"                               (string)
          imagebg="Image background of the domain"                 (Put image in /public/img/assets)
        />

      ***********************************************************************************/}

      <BannerDomain
        logo='qutek'
        text={t('quantum.subtitle')}
        imagebg='qutek.jpg'
      />

      <div className="qutek-body">


        {/*********************************************************************************** 
          Modify HistoryDomain :
          
          <HistoryDomain
            name="Name of Domain"                                    (string)
            text="Text for the domain"                               (string)
            text2="Text for the domain"                              (string)
            image="Image of the domain"                              (Put image in /public/img/assets)
          />

          Tips for text and text2:
          - Use '**' to bold a text: **bold**
          - Use '$' to italic a text: $italic$
          - Use '?' to underline a text: ?underline?
          You can combine them: **$?bold italic underline$?**
        ***********************************************************************************/}

        <HistoryDomain
          name='QUTEK'
          text={t('quantum.history.text1')}
          text2={t('quantum.history.text2')}
          image='qutekmachine.jpg'
        />


        <div className='qutek-technology'>

          <div className='qutek-listhadware'>

            {/*********************************************************************************** 
              Modify QutekHWItem :
              
              <QutekHWItem
                title="title for the item"                               (string)
                image="Image background of the domain"                   (Put image in /public/img/assets)
              />

            ***********************************************************************************/}

            <QutekHWItem
              title={t('quantum.qutekHWItem.superconducting')}
              image="superconducting.jpg"
            />
            <QutekHWItem
              title="PHOTONS"
              image="photons.jpg"
            />
            <QutekHWItem
              title="SPINS"
              image="spins.jpg"
            />
            <QutekHWItem
              title={t('quantum.qutekHWItem.colorcenter')}
              image="colorcenter.jpg"
            />

          </div>

          <div className='qutek-hardware-arrow'>
          </div>

          <div className='qutek-hardware-logo'>
          </div>

          <div className='qutek-hardware-arrow'>
          </div>

          <div className='qutek-systems'>

            <div className='qutek-systems-row'>
              <div className='qutek-systems-item'>
                <div className='qutek-systems-item-title'>
                  {t('quantum.qutekSystems.ebeam.title')}
                </div>
                <div className='qutek-systems-item-text'>
                  {t('quantum.qutekSystems.ebeam.text')}
                </div>
              </div>

              <div className='qutek-systems-item'>
                <div className='qutek-systems-item-title'>
                  {t('quantum.qutekSystems.sputtering.title')}
                </div>
                <div className='qutek-systems-item-text'>
                  {t('quantum.qutekSystems.sputtering.text')}
                </div>
              </div>
            </div>

            <div className='qutek-systems-row'>

              <div className='qutek-systems-item'>
                <div className='qutek-systems-item-title'>
                  {t('quantum.qutekSystems.indium.title')}
                </div>
                <div className='qutek-systems-item-text'>
                  {t('quantum.qutekSystems.indium.text')}
                </div>
              </div>

              <div className='qutek-systems-item'>
                <div className='qutek-systems-item-title'>
                  {t('quantum.qutekSystems.diamond.title')}
                </div>
                <div className='qutek-systems-item-text'>
                  {t('quantum.qutekSystems.diamond.text')}
                </div>
              </div>


            </div>

          </div>

        </div>


        <div className='qutek-brief-history'>

          <div className='qutek-brief-history-header'>
            <div className='qutek-brief-history-title'>
              {t('quantum.briefHistory.title')}
            </div>
            <div className='qutek-brief-history-subtitle'>
              {t('quantum.briefHistory.subtitle')}
            </div>
          </div>

          <hr />

          <div className='qutek-brief-history-container'>

            <div className='qutek-brief-history-left'>
              <div className='qutek-brief-history-text-container'>
                <div className='qutek-brief-history-text'>
                  <ParseText text={t('quantum.briefHistory.text')} />
                </div>
              </div>
              <div className='qutek-brief-history-image'>
                <div className='qutek-timeline-image'></div>
              </div>
            </div>

            <div className='qutek-brief-history-right'>
              <div className='qutek-brief-history-timelinestart'>
                <VerticalTimeline>

                  {/* 1990s important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">1990s</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.1990")}</p>
                  </VerticalTimelineElement>

                  {/* 1998 */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyle}
                    contentArrowStyle={contentArrowStyle}
                    iconStyle={iconStyle}
                  >
                    <h3 className="vertical-timeline-element-title">1998</h3>
                    <p className='vertical-timeline-element-texte'>{t("quantum.timeline.1998")}</p>
                    <h3 className="vertical-timeline-element-title">2002</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2002")}</p>
                  </VerticalTimelineElement>

                  {/* 2003 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2003</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2003")}</p>
                  </VerticalTimelineElement>

                  {/* 2007-2011 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2007-2011</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2007-2011")}</p>
                  </VerticalTimelineElement>

                  {/* 2007 */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyle}
                    contentArrowStyle={contentArrowStyle}
                    iconStyle={iconStyle}
                  >
                    <h3 className="vertical-timeline-element-title">2007</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2007")}</p>
                    <h3 className="vertical-timeline-element-title">2009</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2009")}</p>
                  </VerticalTimelineElement>


                  {/* 2010 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2010</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2010")}</p>
                  </VerticalTimelineElement>

                  {/* 2011-2015 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2011-2015</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2011-2015")}</p>
                  </VerticalTimelineElement>

                  {/* 2015 */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyle}
                    contentArrowStyle={contentArrowStyle}
                    iconStyle={iconStyle}
                  >
                    <h3 className="vertical-timeline-element-title">2015</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2015")}</p>
                  </VerticalTimelineElement>

                  {/* 2016 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2016</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2016-1")}</p>
                  </VerticalTimelineElement>

                  {/* 2016-2 */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyle}
                    contentArrowStyle={contentArrowStyle}
                    iconStyle={iconStyle}
                  >
                    <h3 className="vertical-timeline-element-title">2016</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2016-2")}</p>
                    <h3 className="vertical-timeline-element-title">2019</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2019")}</p>
                    <h3 className="vertical-timeline-element-title">2020</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2020")}</p>
                    <h3 className="vertical-timeline-element-title">2021</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2021-1")}</p>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2021-2")}</p>
                    <h3 className="vertical-timeline-element-title">2022</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2022")}</p>
                    <h3 className="vertical-timeline-element-title">2023</h3>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2023-1")}</p>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2023-2")}</p>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2023-3")}</p>
                    <p className="vertical-timeline-element-texte">{t("quantum.timeline.2023-4")}</p>
                  </VerticalTimelineElement>


                  {/* 2024 important */}
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={contentStyleImportant}
                    contentArrowStyle={contentArrowStyleImportant}
                    iconStyle={iconStyleImportant}
                  >
                    <h3 className="vertical-timeline-element-title-important">2024</h3>
                    <p className="vertical-timeline-element-texte-important">{t("quantum.timeline.2024")}</p>
                  </VerticalTimelineElement>

                </VerticalTimeline>
              </div>
            </div>


          </div>

        </div>

        <div className='qutek-galery'>
          {/*********************************************************************************** 
          Modify GalleryRollAdd :
            
          <GalleryRollAdd
          title="Title of the Accordion"                                      (string)
          images={[ src : "path of the image",                                (choose image in public/...)
                    width : "width of the image",                             (int in format x:1, x:9, ...)
                    height : "height of the image",                           (int in format 1:x, 16:x, ...)
                    alt : "alt of the image",                                 (string)
                    title : "title of the image",                             (string)
                    key : "key of the image"                                  (string)
                  ]}
          />
          ***********************************************************************************/}
          <GalleryRollAdd
            title={t('quantum.gallery')}
            photoPaths={[
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 1.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 2.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 3.jpg",
              "/img/machines/machine4.png",
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 4.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 5.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 S/MEB-Ebeam - PLASSYS MEB 550 S - 6.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 6.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 5.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 4.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 3.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 2.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 1.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 7.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 8.jpg",
              "/img/machines/MEB-Ebeam/MEB 550 SL3 UHV/MEB-Ebeam - PLASSYS MEB 550 SL3 UHV - 9.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 1.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 3.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 5.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 6.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 7.jpg",
              "/img/machines/MEB-Ebeam/MEB 800 S2-III UHV/MEB-Ebeam - PLASSYS MEB 800 S2-III UHV - 9.jpg"
            ]}
          />
        </div>

        <div className='qutek-annexe'>
          <div className='qutek-annexe-document'>

            <div className='qutek-annexe-document-title'>
              Documents
            </div>

            <div className='qutek-annexe-document-download-list'>


              <div className='qutek-annexe-document-download-item'>
                <div className='qutek-annexe-document-download-item-title'>
                  Machine Model Code
                </div>
                <a className='qutek-annexe-document-download-item-button' href='/doc/ModelsCode.pdf' download>
                  {t('annexes.download')}
                  <div className='qutek-annexe-document-download-item-button-icon'>
                  </div>
                </a>
              </div>

              <hr></hr>

              <div className='qutek-annexe-document-download-item'>
                <div className='qutek-annexe-document-download-item-title'>
                  Qutek Flyer
                </div>
                <a className='qutek-annexe-document-download-item-button' href='/doc/Qutek-2022.pdf' download>
                  {t('annexes.download')}
                  <div className='qutek-annexe-document-download-item-button-icon'>
                  </div>
                </a>
              </div>

              <hr></hr>

              <div className='qutek-annexe-document-download-item'>
                <div className='qutek-annexe-document-download-item-title'>
                  Overview of machines categories
                </div>
                <a className='qutek-annexe-document-download-item-button' href='/doc/PlassysOverview.pdf' download>
                  {t('annexes.download')}
                  <div className='qutek-annexe-document-download-item-button-icon'>
                  </div>
                </a>
              </div>


            </div>

          </div>

          <div className='qutek-annexe-line'></div>

          <div className='qutek-annexe-contact'>

            <ParseText text={t("annexes.contact-text")} classN={"qutek-annexe-contact-text"} />

            <NavLink to="/contact" className="qutek-annexe-contact-link">
              <button className="qutek-annexe-contact-button">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                </svg>
                <div className="qutek-annexe-contact-button-text">
                  {t('annexes.contact-button')}
                </div>
              </button>
            </NavLink>

          </div>
        </div>

      </div>

    </div >
  )
}
