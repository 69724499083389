/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ParseText from "../Modules/parseText/ParseText";

/////////////////////////////////////////////
//CSS
import "./css/Recruitment.css";

/////////////////////////////////////////////
//Components

//////////////////////////////////////////////////////////////////////////////////////////

function Recruitment() {
    const { t } = useTranslation();
    document.title = "Plassys ▪ " + t("footer.link4-3");

    return (
        <div className="recrut-container">

            <h1 className="recrut-title">{t("recruitment.title")}</h1>

            <div className="recrut-content">
                <div className="recrut-subtitle">{t("recruitment.motiv")}</div>
                <div className="recrut-text">
                    <ParseText text={t("recruitment.text")} classN={"recrut-text"}/>
                    <Link to="mailto:recruitment@plassys.com" className="recrut-link">{t("navbar.Contact")}</Link>
                </div>
            </div>

        </div>
    );
}
export default Recruitment;
