/////////////////////////////////////////////
//Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

/////////////////////////////////////////////
//CSS
import "./css/Services.css";

/////////////////////////////////////////////
//Components
import GalleryRollAdd from '../Modules/galleryRoll/GalleryRollAdd';

//////////////////////////////////////////////////////////////////////////////////////////

export default function Services() {
    const { t } = useTranslation();
    document.title = "Plassys ▪ " + t("navbar.Services");
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="services-container">
                <h1 className="title-services">NOS SERVICES</h1>
                <div className="services-content">

                    <GalleryRollAdd
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        title="Evaporation HV/UHV"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey2" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey6" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey7" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey8" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey9" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey10" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                        title="Pulvérisation HV/UHV"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey11" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey12" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey13" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey14" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey15" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey16" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey17" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey18" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey19" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey20" },

                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel3'}
                        onChange={handleChange('panel3')}
                        title="Usinage ionique"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey21" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey22" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey23" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey24" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey25" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey26" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey27" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey28" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey29" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey30" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel4'}
                        onChange={handleChange('panel4')}
                        title="Croissance diamant par MW-PACVD"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey31" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey32" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey33" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey34" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey35" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey36" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey37" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey38" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey39" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey40" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel5'}
                        onChange={handleChange('panel5')}
                        title="Machines spéciales ou de production"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey31" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey32" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey33" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey34" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey35" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey36" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey37" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey38" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey39" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey40" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel6'}
                        onChange={handleChange('panel6')}
                        title="Qubit - Systèmes multi-chambres"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey31" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey32" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey33" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey34" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey35" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey36" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey37" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey38" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey39" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey40" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel7'}
                        onChange={handleChange('panel7')}
                        title="Machines en boîte à gants"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey31" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey32" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey33" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey34" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey35" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey36" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey37" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey38" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey39" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey40" },
                        ]}
                        link={"/categories"}
                    />

                    <GalleryRollAdd
                        expanded={expanded === 'panel8'}
                        onChange={handleChange('panel8')}
                        title="Pilotage informatique"
                        content={"Enceintes cubiques à porte frontale, enceintes cylindriques à platine supérieure sur élévateur Sas avec traitement du substrat (plasma, canon à ions, chauffage, oxydation, etc.) Mono ou multi substrats jusqu’à 6″ standard"}
                        images={[
                            { src: "/img/machines/machine1.png", width: 1, height: 1, alt: "testalt", title: "testtitle", key: "testkey31" }, //c'est pas la taille mais juste le format , en mode 1:1 16:9 etc
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey32" },
                            { src: "/img/machines/machine3.png", width: 1, height: 1, key: "testkey33" },
                            { src: "/img/machines/machine4.png", width: 2, height: 1, key: "testkey34" },
                            { src: "/img/machines/machine5.png", width: 2, height: 1, key: "testkey35" },
                            { src: "/img/machines/machine2.png", width: 1, height: 1, key: "testkey36" },
                            { src: "/img/machines/MEB300S.png", width: 1, height: 1, key: "testkey37" },
                            { src: "/img/machines/MEB550S.png", width: 1, height: 1, key: "testkey38" },
                            { src: "/img/machines/MEB550S2_HV.png", width: 1, height: 1, key: "testkey39" },
                            { src: "/img/machines/MEB550S2-I_UHV.png", width: 1, height: 1, key: "testkey40" },
                        ]}
                        link={"/categories"}
                    />

                </div>
            </div>

        </>
    )
}
