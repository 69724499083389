/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//CSS
import "../Modules/productsCat/ProductsCat.css";


/////////////////////////////////////////////
//Components
import Products from "../Modules/productsCat/ProductsCat";

//////////////////////////////////////////////////////////////////////////////////////////

function Categories() {
  const { t } = useTranslation();
  document.title = "Plassys ▪ " + t("navbar.Systems");
  return (
    <>
        <Products />
    </>
  );
}
export default Categories;
