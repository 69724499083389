import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NavbarItemMode from "./NavbarItemMode.jsx";
import NavActivbar from "./NavActivbar.jsx";
import NavLD from "./NavLD.jsx";
import Lang from "../lang/Lang.jsx";

import "./NavbarDropdown.css";

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;

  const [isTop, setIsTop] = useState(true);

  const navLinks = [
    { link: '/presentation', name: t('navbar.Company'), mode: "Link" },
    { link: '/categories', name: t('navbar.Systems'), mode: "Dropdown", dropdownItems: [{ link: '/categories/MEB-Ebeam', name: t('navbar.SystemsDD.MEB') }, { link: '/categories/MP-Sputtering', name: t('navbar.SystemsDD.MP') }, { link: '/categories/ME-Thermal', name: t('navbar.SystemsDD.ME') }, { link: '/categories/CVD Diamond', name: t('navbar.SystemsDD.CVD') }, { link: '/categories/Customized', name: t('navbar.SystemsDD.Customized') }, { link: '/categories/Software', name: t('navbar.SystemsDD.Software') }] },
    { link: '/quantum', name: t('navbar.Quantum'), mode: "Link" },
    { link: '/diamond', name: t('navbar.Diamond'), mode: "Link" },
    { link: '/contact', name: t('navbar.Contact'), mode: "Dropdown", dropdownItems: [{ link: '/contact?service=Global', name: 'Global' }, { link: '/contact?service=Technical', name: t('navbar.ContactDD.Technical') }, { link: '/contact?service=Commercial', name: 'Commercial' }, { link: '/contact/distributors', name: t('navbar.ContactDD.Distributor') },  { link: '/recruitment', name: t('footer.link4-3') }] },
  ];

  const currentPageName = navLinks.find(linkInfo => linkInfo.link === currentPath)?.name || '';

  const checkboxRef = useRef(null);
  const mobileNavRef = useRef(null);

  const updateNavbarState = () => {
    const navbar = document.getElementById('navbar');
    if (window.scrollY > 0 || location.pathname !== '/') {
      navbar.classList.add('scrolled');
      setIsTop(false);
    } else {
      navbar.classList.remove('scrolled');
      setIsTop(true);
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      updateNavbarState();
    };

    window.addEventListener('scroll', scrollHandler);

    // Initial call to set the correct state
    updateNavbarState();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [location]);

  useEffect(() => {
    const checkbox = checkboxRef.current;
    const mobileNav = mobileNavRef.current;

    if (checkbox && mobileNav) {
      checkbox.addEventListener('change', function () {
        if (this.checked) {
          mobileNav.classList.add('open');
          document.body.style.overflow = 'hidden';
        } else {
          mobileNav.classList.remove('open');
          document.body.style.overflow = 'auto';
        }
      });
    }
  }, []);

  const closeMenu = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
      mobileNavRef.current.classList.remove('open');
      document.body.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div id="navbar" className={`navbar ${isTop ? 'top' : ''}`}>
      <Link to="/" >
        <div className="logo">
          <div className="hover-3">
            <div className="LogoImg"></div>
          </div>
        </div>
      </Link>

      <div className="links">
        {navLinks.map((linkInfo, index) => (
          <NavbarItemMode
            key={index}
            linkInfo={linkInfo}
          />
        ))}
        <NavActivbar navLinks={navLinks} />
      </div>

      <div className="navbar-buttons">
        <Lang />
        <NavLD />
      </div>

      <div className="mobile-nav" ref={mobileNavRef}>
        <div className="extra-nav">
          <NavLD />
          <Lang />
        </div>
        <div className="mobile-links">
          {navLinks.map((linkInfo, index) => (
            <React.Fragment key={index}>
              <NavbarItemMode linkInfo={linkInfo} />
              {index < navLinks.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="mobile-activbar">
        <div className="page-name">{currentPageName}</div>
      </div>
      <label className="hamburger">
        <input type="checkbox" ref={checkboxRef}/>
        <svg viewBox="0 0 32 32">
          <path className="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
          <path className="line" d="M7 16 27 16"></path>
        </svg>
      </label>
    </div>
  );
}

export default Navbar;