import React from "react";
import { NavLink } from "react-router-dom";

function DomainAdd({ link,image ,name }) {
  return (
    <div className="card">
      <NavLink to={`categories/${link}`}>
        <img src={`img/machines/${image}`} alt={image}/>
        <div className="card_head">{name}</div>
      </NavLink>
    </div>
  );
}

export default DomainAdd;
