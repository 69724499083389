import React from "react";
import { useTranslation } from "react-i18next";
import ParseText from "../parseText/ParseText";


function Presentation() {
  const { t } = useTranslation();

  return (
    <div className="pres-container" id="pres-container">
      <div className="pres-bg"></div>
      <div className="pres-title">
        <h2>{t("presentation.title")}</h2>
      </div>
      <div className="pres-text">
        <ParseText text={t("presentation.text")} />
      </div>
    </div>
  );
}

export default Presentation;
