/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//CSS
import "./css/Recruitment.css";

/////////////////////////////////////////////
//Components

//////////////////////////////////////////////////////////////////////////////////////////

function LegalMention() {
    const { t } = useTranslation();
    document.title = "Plassys ▪ " + t("footer.link4-5");

    return (
        <div className="recrut-container">

            <h1 className="recrut-title">LEGAL MENTION</h1>

            <div className="recrut-content">
                <div className="recrut-subtitle">Editorial Manager :</div>
                <div className="recrut-text">
                    <ul>
                        <li> Plassys Bestek </li>
                        <li>14 Rue de la Gare, 91630 Marolles-En-Hurepoix</li>
                        <li>01 23 45 67 89</li>
                        <li>client@plassys.com</li>
                    </ul>

                    <div className="recrut-subtitle">Website Host :</div>
                    <div className="recrut-text">
                        <ul>
                            <li>BlueHost</li>
                            <li>www.bluehost.com</li>
                            <li>123 Hosting Street, Server City, USA</li>
                            <li>+1 800-555-5555</li>
                            <li>support@bluehost.com</li>
                        </ul>
                    </div>

                    <div className="recrut-subtitle">Intellectual Property :</div>
                    <div className="recrut-text">
                        <p>
                            The entire content of this website, including but not limited to text, graphics, logos, images, audio clips, video clips, trademarks, and layout, is the exclusive property of PLASSYS unless otherwise indicated. Any unauthorized use of any content from this site without prior written consent from PLASSYS is strictly prohibited.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Cookies :</div>
                    <div className="recrut-text">
                        <p>
                            This website uses cookies to enhance the user experience. Users can manage their cookie preferences through their browser settings.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Hypertext Links :</div>
                    <div className="recrut-text">
                        <p>
                            This site may contain links to external websites. Plassys is not responsible for the content or privacy practices of these sites, and we encourage you to review the privacy policies of these sites.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Limitation of Liability :</div>
                    <div className="recrut-text">
                        <p>
                            PLASSYS shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of this website. This site and its contents are provided on an "as is" basis without any warranties, express or implied.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Applicable Law :</div>
                    <div className="recrut-text">
                        <p>
                            This site is subject to the laws applicable in France.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Contact :</div>
                    <div className="recrut-text">
                        <p>
                            For any questions or concerns regarding these legal notices, please contact us at contact@plassys.com.
                        </p>
                    </div>

                    <div className="recrut-subtitle">Last Updated :</div>
                    <div className="recrut-text">
                        <p>
                            These legal notices were last updated on January 1, 2024.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
}
export default LegalMention;
